<template>
  <div class="mains">
    <!-- 导航栏 -->
    <Navigation :spreadJournalism="'#359efe'"/>

    <div class="news">
      <div class="newsEnglish">{{$globalVar.menu3_desc1}}&nbsp;{{$globalVar.menu3_desc2}}</div>
      <div class="newsChinese">{{$globalVar.menu3}}</div>
    </div>

    <!-- <div class="news-detail">
      <div class="newsTitle">
        消费级基因检测 精准医疗已成大势所趋撒发生采访时
      </div>
      <div class="newsTime">2021-07-17 16:48:20</div>
      <div class="cont">
        <div class="text">
          “传统治疗方案显示，肿瘤的无效率高达75%，糖尿病无效率43%，抑郁症无效率也有38%。”在日前举行的“第四届精准医疗与基因测序大会”上，中国工程院院士、北京大学医学部主任詹启敏给出了上面的数据。怀着对精准医疗、精准健康的强烈期待，与会
          的医学、遗传学专家学者、基因业界精英，就基因测序的技术和作用进行了深入交流。让基因检测普及，让更多人认识和使用基因检测，对于精准医疗、精准保健意义重大。
        </div>
        <img
          class="picture"
          src="../../assets/img/Journalism/details/pic1.jpg"
          alt=""
        />
        <div class="text">
          6月30日，备受期待的第四届精准医疗与基因测序大会在北京协和学术会堂隆重召开，近千人参加了这场盛会。中国工程院程京院士、詹启敏院士、北京大学肿瘤医院院长季加孚教授等50余位国内外嘉宾出席了本届大会并做了精彩报告，就基因测序的技术和应用等方面，进行了深入的交流和探讨。
        </div>
        <div class="text">
          传统的循证医学对于症状相同的病人使用相同剂量的同种药物进行治疗，但效果却千差万别；而基因测序将推动精准医疗实现“同病异治”和“异病同治”。正如詹启敏院士所说，未来精准医学应该将贯穿包括疾病风险预测、诊断及分类、药物应用、疗效评估、预后预测在内的疾病全过程。而要实现“精准”，就离不开基因测序。
        </div>
        <div class="text">
          最近几年，中国的基因测序市场规模飞速发展。前瞻产业研究院发布的数据显示，中国基因测序市场已于2016年突破50亿人民币，预计2022年将达到300亿人民币的市场规模。
        </div>
        <img
          class="picture"
          src="../../assets/img/Journalism/details/pic2.jpg"
          alt=""
        />
        <div class="text">
          而在其中，消费级基因检测的普及应用举足轻重。因为，如果一个人生病到了医院后被要求做基因检测的话，其实已经晚了一步
          --
          疾病已在体内形成。而消费级基因检测像普通商品一样，消费者可以便捷购买、便捷采样和接受报告，就能够提前预知疾病风险，精准地进行身体养护，防病于未然。
        </div>
        <div class="text">
          以圆方基因为代表的消费级基因检测公司，已经在致力于推动基因检测的普及和多场景应用，让更多人提前预知自己的疾病风险、药物代谢差异，从而尽早迈向精准医疗。随着技术不断发展，目前国内的消费级基因检测产品已降至几百元的水平。消费级基因检测从技术层面上说，和临床级、科研级的基因检测没有很大差异，“消费级”这三个字，更多定义的是后期评估模型或应用场景。
        </div>
        <div class="text">
          基因检测出来的大量数据对普通用户来说难以准确理解、有效应用。因此，检测之后的专业解读和指导，将数据使用起来、揭示生命数据的价值，是更为重要的一件事情，也是圆方基因的创新和重点所在。
        </div>
        <div class="text">
          精准医疗、精准预防已成大势所趋，圆方基因也将持续致力于消费级基因检测领域。
        </div>
        <div class="text">来源：测序中国</div>
      </div>
    </div> -->
    
    <div v-html="content" class="news-detail"></div>

  </div>
</template>

<script>
import Navigation from "../../components/Navigation.vue"; // 导航栏组件

export default {
  components: {
    Navigation,
  },
  data() {
    return {
      content: '',
    };
  },
  mounted() {
    this.$api.newsDetail({ 'newsId': this.$route.query.id})
    .then((res) => {
      if (res.code == 0) {
        this.content = res.data.newsContent;
      } else {

      }
    })
    .catch(err => {
      console.log('网络出错，请稍后再试！');
    })
  }
};
</script>

<style lang="less">
.mains {
  // 一行文字溢出
  .oneSpill {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  // 两行文字溢出
  .twoSpill {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  // 三行文字溢出
  .threeSpill {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
  @media (min-width: 320px) and (max-width: 750px) {
    .news {
      margin-top: 0.615rem;
      background: url("../../assets/img/h5/Journalism/banner_bg.png") no-repeat;
      background-size: 7.5rem 100%;
      padding: 1.43rem 1.25rem 1.4rem 1.18rem;
      text-align: center;
      color: #fff;
      .newsEnglish {
        font-size: 0.24rem;
        font-weight: bold;
        letter-spacing: 0.095rem;
      }
      .newsChinese {
        font-size: 0.36rem;
        font-weight: bold;
        letter-spacing: 0.08rem;
        padding-top: 0.14rem;
      }
    }

    // 资讯详情
    .news-detail {
      padding: 0.6rem 0.28rem 0.7rem;
      font-size: 0.3rem;
      p {
        line-height: 0.46rem;
        padding: 0.1rem 0;
      }
      img {
        width: 100%;
      }
      .newsTitle {
        padding: 0 0.15rem;
        text-align: center;
        line-height: 0.5rem;
        font-size: 0.38rem;
        font-weight: bold;
        color: #111111;
      }
      .newsTime {
        text-align: center;
        height: 0.0829rem;
        font-size: 0.26rem;
        font-weight: 500;
        color: #999999;
        padding: 0.35rem 0 0.2rem 0;
      }
      .cont {
        padding-top: 0.45rem;
        color: #333333;
        font-weight: 500;
        font-size: 0.3rem;
        .text {
          line-height: 0.5rem;
          padding-bottom: 0.3rem;
        }

        .picture {
          height: 100%;
          width: 100%;
          padding: 0.152rem 0 0.4rem 0;
        }
      }
    }
  }
  @media (min-width: 751px) and (max-width: 1023px) {
    .news {
      background: url("../../assets/img/h5/Journalism/banner_bg.png") no-repeat;
      background-size: 7.5rem 100%;
      padding: 1.43rem 1.25rem 1.4rem 1.18rem;
      text-align: center;
      color: #fff;
      .newsEnglish {
        font-size: 0.2rem;
        font-weight: bold;
        letter-spacing: 0.095rem;
      }
      .newsChinese {
        font-size: 0.24rem;
        font-weight: bold;
        letter-spacing: 0.08rem;
        padding-top: 0.14rem;
      }
    }

    // 资讯详情
    .news-detail {
      padding: 0.37rem 0.28rem 0.5rem;
      font-size: 0.17rem;
      p {
        line-height: 0.25rem;
        padding: 0.05rem 0;
      }
      img {
        width: 100%;
      }
      .newsTitle {
        text-align: center;
        line-height: 0.37rem;
        font-size: 0.26rem;
        font-weight: bold;
        color: #111111;
      }
      .newsTime {
        text-align: center;
        font-size: 0.23rem;
        font-weight: 500;
        color: #999999;
        padding: 0.2rem 0;
      }
      .cont {
        color: #333333;
        font-weight: 500;
        font-size: 0.2rem;
        .text {
          line-height: 0.32rem;
          padding-bottom: 0.2rem;
        }

        .picture {
          height: 100%;
          width: 100%;
          padding: 0 0 0.3rem 0;
        }
      }
    }
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    .news {
      position: relative;
      background: url("../../assets/img/Journalism/news.jpg") no-repeat;
      background-size: 10rem 100%;
      padding: 1.4rem 2.93rem 1.4219rem 3.375rem;
      text-align: center;
      .newsEnglish {
        font-size: 0.1563rem;
        font-weight: 400;
        letter-spacing: 0.08rem;
        padding-bottom: 0.2rem;
        color: rgba(255, 255, 255, 1);
      }
      .newsChinese {
        font-size: 0.375rem;
        font-weight: 500;
        color: #ffffff;
        letter-spacing: 0.08rem;
      }
    }
    // 资讯详情
    .news-detail {
      width: 81%;
      margin: 0.3rem auto 0.4rem;
      font-size: 0.15rem;
      p {
        line-height: 0.24rem;
        padding: 0.05rem 0;
      }
      img {
        width: 100%;
      }
      .newsTitle {
        text-align: center;
        line-height: 0.39rem;
        font-size: 0.27rem;
        font-weight: bold;
        color: #222222;
      }
      .newsTime {
        text-align: center;
        font-size: 0.19rem;
        font-weight: bold;
        color: #999999;
        padding-top: 0.2rem;
      }
      .cont {
        padding-top: 0.31rem;
        .text {
          font-size: 0.186rem;
          line-height: 0.31rem;
          padding-bottom: 0.27rem;
        }
        .picture {
          height: 100%;
          width: 100%;
          padding-bottom: 0.28rem;
        }
        
      }
    }
  }
  @media (min-width: 1280px) {
    .news {
      position: relative;
      background: url("../../assets/img/Journalism/news.jpg") no-repeat;
      background-size: 10rem 100%;
      padding: 1.4rem 2.93rem 1.4219rem 3.375rem;
      text-align: center;
      .newsEnglish {
        font-size: 0.17rem;
        font-weight: 400;
        letter-spacing: 0.08rem;
        padding-bottom: 0.2rem;
        color: rgba(255, 255, 255, 1);
      }
      .newsChinese {
        font-size: 0.2rem;
        font-weight: 500;
        color: #ffffff;
        letter-spacing: 0.08rem;
      }
    }
    // 资讯详情
    .news-detail {
      width: 64%;
      margin: 0.3rem auto 0.4rem;
      font-size: 0.1rem;
      p {
        line-height: 0.16rem;
        padding: 0.05rem 0;
      }
      .newsTitle {
        text-align: center;
        line-height: 0.2345rem;
        font-size: 0.1775rem;
        font-weight: bold;
        color: #222222;
      }
      .newsTime {
        text-align: center;
        height: 0.0829rem;
        font-size: 0.0938rem;
        font-weight: bold;
        color: #999999;
        padding-top: 0.1159rem;
      }
      .cont {
        padding-top: 0.31rem;
        .text {
          font-size: 0.11rem;
          line-height: 0.175rem;
          padding-bottom: 0.19rem;
        }
        .picture {
          height: 100%;
          width: 100%;
          padding-bottom: 0.19rem;
        }
      }
    }
  }
}
</style>>
